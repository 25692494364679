import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import FileInput from './FileInput';
import ApiCaller from './ApiCaller';
import ApiCallerNewPlan from './ApiCallerNewPlan'
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import ApiCallerFlamelink from './ApiCallerFlamelink';

function App() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [error, setError] = useState('');
  const [usernameError, setUsernameError] = useState('')
  const [temperature, setTemperature] = useState(0.5);
  const [topP, setTopP] = useState(0.8)
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState('')


  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [response, setResponse] = useState(null);
  const [response2, setResponse2] = useState(null)
  const [response3, setResponse3] = useState(null)

  const handleFileChange = (newFile) => {
    setFile(newFile);
  };

  const handleFile2Change = (newFile) => {

    setFile2(newFile)

  }

  const handleFile3Change = (newFile) => {
    setFile3(newFile);
  }

  const handleResponse = (data) => {
    setResponse(data);
  };

  const handleResponse2 = (data) => {
    setResponse2(data);
  };

  const handleResponse3 = (data) => {
    setResponse3(data);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleTemperatureChange = (e) => {
    const inputTemperature = e.target.value;
    // Check if the input is a valid number between 0 and 1
    if (/^[01](\.\d+)?$/.test(inputTemperature)) {
      setTemperature(inputTemperature);
      setError('');
    } else {
      setTemperature(inputTemperature);
      setError('Temperature must be a number between 0 and 1.');
    }
  };

  const handleUsernameChange = (e) => {
    let inputUsername = e.target.value

    if (inputUsername) {
      setUsername(inputUsername)
      setUsernameError('')

    } else {
      setUsername(inputUsername);
      setUsernameError('Username is invalid')
    }


  }

  const handleTopPChange = (e) => {
    const inputTopP = e.target.value;
    // Check if the input is a valid number between 0 and 1
    if (/^[01](\.\d+)?$/.test(inputTopP)) {
      setTopP(inputTopP);
      setError('');
    } else {
      setTopP(inputTopP);
      setError('Temperature/Top_p must be a number between 0 and 1.');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch('https://dolphin-app-zx3nc.ondigitalocean.app/prompt', {
        method: 'POST', // Change the method as per your API requirements
        body: JSON.stringify({ prompt: inputText, temperature, top_p: Number(topP) }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setOutputText(data.output);
    } catch (error) {
      console.error('API Error:', error);
      setOutputText('API Request Failed');
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="App">
      <textarea
        rows="10"

        value={inputText}
        onChange={handleInputChange}
        placeholder="Enter prompt"
      />


      <div>
        <label htmlFor="temperatureInput">Temperature: </label>
        <input
          placeholder="temperature"
          value={temperature}
          onChange={handleTemperatureChange}
        />
        <p style={{ color: 'red' }}>{error}</p>
      </div>
      <br />
      <br />


      <div>
        <label htmlFor="Top_p">Top_p: </label>
        <input
          placeholder="top_p"
          value={topP}
          onChange={handleTopPChange}
        />
      </div>

      <br />
      <br />

      <button onClick={handleSubmit} disabled={loading}>
        Submit
      </button>
      {loading && <p>Loading...</p>}
      {outputText && <div>Output: {outputText}</div>}

      <br></br>
      <br></br>

      <h1>Upload PDF and Extract Data</h1>

      <FileInput onFileChange={handleFileChange} />
      <br>
      </br>
      <ApiCaller file={file} onResponse={handleResponse} />
      <br></br>
      {response && (
        <div>
          <h2>API Response:</h2>
          {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
          <JsonView
            data={response}
            shouldExpandNode={allExpanded} style={defaultStyles}
          />
        </div>
      )}


      <br></br>
      <br></br>

      <h1>PDF Extraction and Flamelink Data Sync</h1>


      <div>
        <label htmlFor="username1">Username: </label>
        <input
          placeholder="username"
          value={username}
          onChange={handleUsernameChange}
        />
        <p style={{ color: 'red' }}>{usernameError}</p>
      </div>
      <br />
      <br />

      <FileInput onFileChange={handleFile2Change} />
      <br>
      </br>
      <ApiCallerFlamelink username={username} file={file2} onResponse={handleResponse2} />
      <br></br>
      {response2 && (
        <div>
          <h2>API Response:</h2>
          {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
          <JsonView
            data={response2}
            shouldExpandNode={allExpanded} style={defaultStyles}
          />
        </div>
      )}

      <h1>PDF Extraction for New Plans</h1>
      <br />

      <FileInput onFileChange={handleFile3Change} />
      <br>
      </br>
      <ApiCallerNewPlan file={file3} onResponse={handleResponse3} />
      <br></br>
      {response3 && (
        <div>
          <h2>API Response:</h2>
          {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
          <JsonView
            data={response3}
            shouldExpandNode={allExpanded} style={defaultStyles}
          />
        </div>
      )}


    </div>
  );
}

export default App;
