// src/components/ApiCaller.js
import React, { useState } from 'react';

const ApiCallerFlamelink = ({ username, file, onResponse }) => {

    const [loading, setLoading] = useState(false);

    const handleApiCall = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('pdf', file);

        formData.append('username', username)

        try {
            const response = await fetch('https://dolphin-app-zx3nc.ondigitalocean.app/process-pdf-with-flamelink-sync', {
                method: 'POST',
                body: formData,
            });
            setLoading(false)
            const data = await response.json();
            onResponse(data);
        } catch (error) {
            setLoading(false)
            console.error('API call error:', error);
        }
    };

    return (
        <div>
            <button onClick={handleApiCall}>Call API</button>
            {loading && <p>Loading...</p>}
        </div>
    );
};

export default ApiCallerFlamelink;
